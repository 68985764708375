// import { crmParamDetail } from './crmParamReducer';

import { adminAccountDetail } from "./adminAccountReducer";

export const admin_account_details = (query) => async (dispatch) => {
    try {
        dispatch(adminAccountDetail(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        // throw error;
    }
};

