import { AppBar, Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { formatBytes } from '../utils/commonFunction';
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const PlanAccess = ({ orgId, orgIds,setOrgId }) => {
    const dispatch = useDispatch();
    const planInfo = useSelector((state) => state?.adminAccountDetails);
    const timezones = moment.tz.names();
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [switchValues, setSwitchValues] = useState({ type: 2, org_id: '', plan_type: 2, end_date: '', name: 'PROFESSIONAL_PLAN', timezone: moment.tz.guess(), subscription_type: 1 });
    const [accessValues, setAccessValues] = useState({
        type: 1, org_id: 0, max_documents: 0, max_storage_limit: 0, contacts_limit: 0, max_templates: 0, max_users: 0,
        envelope_limit: 0, api_request_imit: 0, current_storage_limit: 0
    });

    const planHead = planInfo?.tableinfo
    const planName = planInfo?.values?.plan_names
    const planConstants = planInfo?.values?.plan_constants


    const keyValSwap = {
        max_documents: 'Documents',
        max_templates: 'Templates',
        contacts_limit: 'Contacts',
        max_users: 'Users',
        api_request_imit: 'API Request Limit',
        envelope_limit: 'Envelope Limit',
        max_storage_limit: 'Storage Limit',
        current_storage_limit: 'Current Storage Limit',
    }

    useEffect(() => {
        handlePlan('Professional')
    }, [planConstants])

    useEffect(() => {
        setSwitchValues({ ...switchValues, org_id: orgId })
    }, [orgId])

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getKeyByValue = (obj, value) =>
        Object.keys(obj).find(key => obj[key] === value);


    const handleValueChange = (e, val) => {
        const key = getKeyByValue(keyValSwap, val)

        setAccessValues(pre => ({ ...pre, [key]: e.target.value }))
    }

    const handleSubmit = (e) => {
        let data;
        if (e === 'access') {
            data = {
                "org_id": Number(accessValues.org_id),
                "max_documents": Number(accessValues.max_documents),
                "max_storage_limit": accessValues.max_storage_limit,
                "contacts_limit": accessValues.contacts_limit,
                "max_templates": accessValues.max_templates,
                "max_users": accessValues.max_users,
                "envelope_limit": accessValues.envelope_limit,
                "api_request_imit": accessValues.api_request_imit,
                "current_storage_limit": Number(accessValues.current_storage_limit),
            }

            for (let key in data) {
                if (data[key] === 0) {
                    delete data[key];
                }
            }
        }
        else if (e === 'switch') {
            data = {
                "org_id": switchValues.org_id,
                "plan_type": switchValues.plan_type,
                "end_date": switchValues.end_date,
                "timezone": switchValues.timezone,
                "subscription_type": switchValues.subscription_type,
                "max_documents": Number(accessValues.max_documents),
                "max_storage_limit": Number(accessValues.max_storage_limit),
                "contacts_limit": accessValues.contacts_limit,
                "max_templates": accessValues.max_templates,
                "max_users": accessValues.max_users,
                "envelope_limit": accessValues.envelope_limit,
                "api_request_imit": accessValues.api_request_imit,
                "current_storage_limit": Number(accessValues.current_storage_limit),
            }
        }
        let withCredentials = {
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }

        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_PLAN, data, withCredentials)
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success("Success");
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            })
    }
    const handleTime = (e) => {
        let val = dayjs(e.$d).format("YYYY-MM-DDTHH:mm:ss");
        setSwitchValues({ ...switchValues, end_date: val })
    }

    const handlePlan = (name) => {
        setSwitchValues({ ...switchValues, name: name })
        planHead && Object.keys(planHead).forEach(key => {
            if (key === name) {
                setAccessValues({
                    ...accessValues,
                    type: 1,
                    org_id: 0,
                    max_documents: planHead[key].documents,
                    max_storage_limit: planHead[key].product_overall_storage_limit_bytes,
                    contacts_limit: planHead[key].contacts,
                    max_templates: planHead[key].templates,
                    max_users: planHead[key].users,
                    envelope_limit: planHead[key].envelope_limit,
                    api_request_imit: planHead[key].api_request_limit,
                    current_storage_limit: planHead[key].current_storage_limit_bytes
                })
            }

        })
    }

    return (
        <Box sx={{ display: "flex", justifyContent: ' space-between', flexDirection: 'row-reverse' }}>
            <Box sx={{ display: 'flex', gap: '30px', padding: "40px" }}>
                <Box sx={{ paddingTop: '5px', width: '100px', textAlign: 'center' }} >Org Id:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={orgId}
                        onChange={(e) => setOrgId(e.target.value)}
                        style={{ height: 35, width: '150px' }}
                        disabled={orgIds.length >= 1 ? false : true}
                    >
                        {orgIds?.map((table, i) => (<MenuItem key={i} value={table}>{table}</MenuItem>))}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ paddingTop: "40px", width: '65%' }}>
                <AppBar sx={{ width: 184, backgroundColor: 'white', color: 'black', borderRadius: '5px' }} position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                    >
                        <Tab label="Switch" {...a11yProps(0)} />
                        <Tab label="Access" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    <Box  className='plan_access_table' sx={{
                        overflowX: 'hidden',
                        height: '77vh',
                        
                    }}>
                        <Box sx={{ padding: "30px", width: '650px' }}>
                            <Box >
                                <Box>
                                    {/* <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Type:</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput value={switchValues.type} onChange={(e) => setSwitchValues({ ...switchValues, type: e.target.value })} style={{ height: 35, width: '270px' }} placeholder='Enter type' disabled />
                                    </FormControl>
                                </Box> */}
                                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                        <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Org Id:</Box>
                                        <FormControl size="small" >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={orgId}
                                                onChange={(e) => setOrgId(e.target.value)}
                                                 style={{ height: 35, width: '270px' }} placeholder='Enter Org Id"'
                                                disabled={orgIds.length >= 1 ? false : true}
                                            >
                                                {orgIds?.map((table, i) => (<MenuItem key={i} value={table}>{table}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        {/* <FormControl size="small" >
                                            <OutlinedInput type='Number' value={switchValues.org_id} 
                                            // onChange={(e) => setSwitchValues({ ...switchValues, org_id: Number(e.target.value) })}
                                             style={{ height: 35, width: '270px' }} placeholder='Enter Org Id"' />
                                        </FormControl> */}
                                    </Box>
                                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                        <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Plan Type:</Box>
                                        <FormControl size="small" >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={switchValues.plan_type}
                                                name={switchValues.name}
                                                onChange={(e) => setSwitchValues({ ...switchValues, plan_type: e.target.value })}
                                                style={{ height: 35, width: '270px' }}
                                            >
                                                {planName?.map((val) => (
                                                    <MenuItem value={planConstants[val]} onClick={() => handlePlan(val)}>{val}</MenuItem>
                                                ))}
                                                {/* <MenuItem value={1} onClick={() => handlePlan('Free')}>FREE PLAN</MenuItem>
                                            <MenuItem value={2} onClick={() => handlePlan('Professional')} >PROFESSIONAL PLAN</MenuItem>
                                            <MenuItem value={3} onClick={() => handlePlan('Enterprise')}>ENTERPRISE PLAN</MenuItem>
                                            <MenuItem value={5} onClick={() => handlePlan('Professional-Trial')}>PROFESSIONAL TRAIL</MenuItem>
                                            <MenuItem value={6} onClick={() => handlePlan('Enterprise-Trial')}>ENTERPRISE TRIAL</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                        <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Time Zone</Box>
                                        <FormControl size="small" >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={switchValues.timezone}
                                                style={{ height: 35, width: '270px' }}
                                                onChange={(e) => setSwitchValues({ ...switchValues, timezone: e.target.value })}
                                            >
                                                {timezones.map((tz) => (
                                                    <MenuItem key={tz} value={tz}>
                                                        {tz}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                        <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Subscription Type:</Box>
                                        <FormControl size="small" >
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={switchValues.subscription_type}
                                                onChange={(e) => setSwitchValues({ ...switchValues, subscription_type: e.target.value })}
                                                style={{ height: 35, width: '270px' }}
                                            >
                                                <MenuItem value={1} >MONTHLY</MenuItem>
                                                <MenuItem value={2}>YEARLY</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {/* <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >User Id:</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput value={switchValues.user_id} onChange={(e) => setSwitchValues({ ...switchValues, user_id: Number(e.target.value) })} style={{ height: 35, width: '270px' }} placeholder='Enter User Id' />
                                    </FormControl>
                                </Box> */}

                                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                        <Box sx={{ paddingTop: '30px', width: '200px', textAlign: 'center' }} >End Date:</Box>
                                        <FormControl size="small" >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DateTimePicker']}>
                                                    <DateTimePicker onChange={handleTime} label="End Date" />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        {Object.values(keyValSwap).map((val) =>
                                            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >{val}</Box>
                                                <FormControl size="small" sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }} >
                                                    <OutlinedInput value={accessValues[getKeyByValue(keyValSwap, val)]}
                                                        type='Number'
                                                        onChange={(e) => handleValueChange(e, val)}
                                                        style={{ height: 35, width: '270px' }}
                                                        placeholder={val}
                                                    />
                                                    {(val === 'Storage Limit' || val === 'Current Storage Limit') && (<Box sx={{ paddingTop: '8px' }}> {formatBytes(accessValues[getKeyByValue(keyValSwap, val)])}</Box>)}
                                                </FormControl>
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                                        <Button variant="contained"
                                            onClick={() => handleSubmit("switch")}
                                        >Submit</Button>
                                    </Box>
                                </Box>

                            </Box>
                        </Box>
                    </Box >
                    <Box sx={{ padding: "40px", width: '575px' }}>
                        <Box>
                            {Object.values(keyValSwap).map((val) =>
                                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >{val}</Box>
                                    <FormControl size="small" >
                                        <OutlinedInput value={accessValues[getKeyByValue(keyValSwap, val)]}
                                            onChange={(e) => handleValueChange(e, val)}
                                            style={{ height: 35, width: '270px' }}
                                            placeholder={val}
                                        />
                                    </FormControl>
                                </Box>
                            )}
                            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                                <Button variant="contained"
                                    onClick={() => handleSubmit("access")}
                                >Submit</Button>
                            </Box>
                        </Box>
                    </Box>
                </SwipeableViews >
            </Box >
        </Box>
    )
}

export default PlanAccess