import { Select } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import styles from './CustomSelect.module.css';

const CustomSelect = ({ element = <></>, children, condition, type }) => {
    return (
        <>
            {condition === true ? (
                <Box className={styles.more_condition_icon_button}>
                    {element}
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            classes: { paper: type ? styles.select_condition_more_options_type : styles.select_condition_more_options },
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root:first-of-type': {
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                        },
                                        '&.Mui-selected:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    },
                                },
                            }
                        }}
                        style={{ opacity: '0', position: 'absolute' }}
                        value=''
                    >
                        {children}
                    </Select>
                </Box>
            ) : (
                <Box className={styles.more_icon_button}>
                    {element}
                    <Select
                        MenuProps={{
                            classes: { paper: styles.select_more_options },
                            PaperProps: {
                                sx: {
                                    '& .MuiMenuItem-root:first-of-type': {
                                        backgroundColor: 'transparent',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                        },
                                        '&.Mui-selected:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                        },
                                    },
                                },
                            }
                        }}
                        sx={{
                            "& .MuiInputBase-root": {
                              marginRight: "30px !important",
                            },
                          }}
                        style={{ opacity: '0', position: 'absolute' }}
                        value=''
                    >
                        {children}
                    </Select>
                </Box>
            )}
        </>
    );
};

export default CustomSelect;